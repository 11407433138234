import { Link } from "gatsby";
import React from "react";

const ErrorTemplate = () => {
  return (
    <section className="section-page404">
      <img src="images/404.jpg" alt="Fundo página 404"/>
      <div className="section-page404-message">
        <h1>Erro 404</h1>
        <p>
          Pedimos desculpa mas esta página não existe <br /> enrolar punho para
          a <Link to="/">homepage</Link>
        </p>
      </div>
    </section>
  );
};

export default ErrorTemplate;
